a:hover {
    color: #2E3B42 !important;
    opacity: .7;
}

.active {
    color: #A2ADFF;
}
.text-first{
    color: #A2ADFF !important;
}

.text-second {
    color: #a69245 !important;
}

.text-third {
    color: #BBBBBB !important;
}

.bg-first,
.btn-first {
    background: #A2ADFF;
    color: #2E3B42;
}

.btn-outline-first {
    border: 1px solid #A2ADFF;
    color: #2E3B42;
    transition: .25s ease-in-out;
}

.btn-outline-first:hover {
    border: 1px solid #A2ADFF;
    color: #2E3B42 !important;
    opacity: .7 !important;
}

.bg-second,
.btn-second {
    background: #a69245;
    color: #fff;
}

.bg-third {
    background: #797874FF;
    color: #fff;
}

.bg-fourth {
    background: #a69245;
}

.bg-glass {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid #A2ADFF;
}
