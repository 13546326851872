body {
    overflow-x: hidden;
}

#front-end {
    font-family: 'Poppins', sans-serif;
    background: #EFEFEF !important;

    .card {
        border: none !important;
    }
}

h1, .h1 {
    font-size: calc(4rem + 0.1vw);
}

h5, .h5 {
    font-size: 1.2rem;
}

a{
    text-decoration: none;
    transition: .25s ease-in-out;
}

section {
    padding-top: 125px;
    padding-bottom: 125px;
}

.border-none {
    border: none !important;
    border-radius: 0;
}

#footer-card {
    margin-bottom: -50px;
}

#footer {
    padding-top: 110px;
}
