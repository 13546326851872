#hero {
    background: url(/img/home/hero.jpg) no-repeat center center;
    background-size: cover;
    padding-bottom: 349px;
    padding-top: 20%;
}

@media (min-width:508px) and (max-width:1024px) {
    #hero{
        padding-bottom: 250px;
        padding-top: 15%;
    }
}

@media (min-width: 1024px) {
    #hero{
        padding-bottom: 165px;
        padding-top: 12%;
        margin-top: -100px;
    }
}

.reg-input-submit {
    z-index: 9 !important;
    position: absolute !important;
    right: 0;
    border: 0 !important;
}

.reg-input-input {
    border: 0 solid #FFDE59;
    border-radius: 5px !important;
}

.reg-input {
    z-index: 1 !important;
    background: #FFDE59 !important;
    color: #000 !important;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 2.5em !important;
    padding: 0 !important;
    line-height: normal !important;
}

.service-card {
    background-size: cover !important;
    transition: transform .2s;
    height: 250px;
}
.service-card:hover {
    transform: scale(1.03);
}

#home-content .overlap-cards {
    margin-top: -250px;
}
