//Frontend Navbar
#contact-bar {

}

.frontend-navbar {

    .navbar-toggler {
        box-shadow: none !important;
        border: none !important;
    }

    .navbar-brand {
        margin: -20px 0 -20px 0;
    }

    .dropdown-toggle::after {
        display: none;
    }
}

//@media (min-width: 991px) {
//    .frontend-navbar {
//        margin-top: 79px;
//    }
//}

//Auth Navbar
.auth-nav {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

#content {
    margin-left: 250px;
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

.content-no-side-nav {
    margin-left: 0 !important;
}
