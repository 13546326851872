#page-hero {
    background-size: cover;
    padding-bottom: 12%;
    padding-top: 12%;
}

#img {
    width: 100%;
    height: 100%;
    object-position: 0% 0%;
    -o-object-fit: cover;
    object-fit: cover;
}

#cover {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
